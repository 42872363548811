<template>
  <div class="grid gap-2.5">
    <div v-if="!hideSearch">
      <ui-fields-input-search
        v-model="search"
        sync-v-model
        enabled-controlled
        placeholder="Enter a user name"
        @input="onSearch"
      />
    </div>
    <div
      class="overflow-auto"
      :class="wrapClasses"
    >
      <ui-virtual-scroller
        dependencies-key="id"
        :show-loader="isFirstLoad"
        :items="items"
        :loading="loading"
        :is-last-page="isLastPage"
        class="sm:divide-y-0 divide-y divide-card-placeholder-border"
        @load-more="onLoadMore"
      >
        <template #item="{ item }">
          <component
            :is="roomUserCardComponent"
            :user="item"
            :update-type="type"
            :is-swiped-menu="item.id === activeSwipe"
            @toggle-swipe="onToggleSwipe"
          />
        </template>
        <template #empty>
          <ui-typography
            text="There are no users"
            class="text-center pt-3 min-h-[70px] flex items-center justify-center"
            variant="body"
          />
        </template>
      </ui-virtual-scroller>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { TRoomUsersFilterQuery } from '~/globals/rooms';
  import { CommonRoomUserAdminCard, CommonRoomUserMemberCard, CommonRoomUserRequestCard } from '#components';

  type Props = {
    type: TRoomUsersFilterQuery;
    cardType?: TRoomUsersFilterQuery;
    hideSearch?: boolean;
    wrapClasses?: ClassesType;
  };
  const props = defineProps<Props>();
  const search = ref();
  const activeSwipe = ref(0);
  const roomUserCardComponent = computed(() => {
    return {
      [RoomUsersFilterQuery.Members]: CommonRoomUserMemberCard,
      [RoomUsersFilterQuery.Requests]: CommonRoomUserRequestCard,
      [RoomUsersFilterQuery.Admins]: CommonRoomUserAdminCard
    }[props.cardType || props.type];
  });

  const roomMemberStore = useRoomMemberStore();
  const roomMemberState = computed(() => {
    return roomMemberStore.users[props.type];
  });
  const items = computed(() => roomMemberState.value.data);
  const isFirstLoad = computed(() => roomMemberState.value.isFirstLoad);
  const loading = computed(() => roomMemberState.value.loading);
  const isLastPage = computed(() => !roomMemberStore.checkNextPageUsers(props.type));
  const onFetchData = async (next?: boolean) => {
    await roomMemberStore.fetchMembers(
      props.type,
      {
        search: search.value
      },
      next
    );
  };

  const onFetchDebounce = useDebounce(onFetchData, 600);

  const onSearch = (val: string) => {
    search.value = val;
    onFetchDebounce();
  };

  const onLoadMore = async () => {
    if (!isLastPage.value) {
      await onFetchData(true);
    }
  };

  const onToggleSwipe = (cardId: number) => {
    activeSwipe.value = cardId;
  };

  onMounted(() => onFetchData());
</script>
