<template>
  <common-room-user-base-card :user="user">
    <common-room-user-status
      v-if="!userStatus.member"
      :status="status"
    />
    <template v-if="!userStatus.owner">
      <ui-button
        v-if="isMobile"
        variant="primary"
        size="sm-icon"
        icon-left="edit"
        @click="openAdminModal"
      />
      <ui-dropdown-menu
        v-else
        placement="top-end"
        :skidding="18"
        :distance="-50"
        :menu-items="menuItems"
        class="absolute sm:top-1 -top-1 sm:right-4 right-0"
      />
    </template>
  </common-room-user-base-card>
</template>

<script setup lang="ts">
  import type { TRoomAdminPermission, TRoomUsersFilterQuery } from '~/globals/rooms';

  type Props = {
    user: RoomUser;
    updateType: TRoomUsersFilterQuery;
  };
  const props = defineProps<Props>();
  const isMobile = useIsLessThan('sm');
  const status = computed(() => props.user.user_status?.status);
  const userStatus = computed(() => getUserStatusForRoom(props.user));
  const roomStore = useRoomStore();
  const roomMemberStore = useRoomMemberStore();
  const { open: openAdminPermissionsModal, close: closeAdminPermissionsModal } = useAdminPermissionsModal({});
  const userName = useUserFullName(props, 'user');

  const { action: openRemoveAdminModal } = useConfirmRemoveAdminModal();
  const { action: openDeleteUser } = useConfirmDeleteUserFormRoom();
  const confirmRemoveAdmin = async () => {
    await openRemoveAdminModal(
      {
        roomId: roomStore.roomId,
        userId: props.user.id,
        userName: userName.value
      },
      () => {
        roomMemberStore.deleteUser(RoomUsersFilterQuery.Admins, props.user.id);
        roomMemberStore.deleteUserFromAdmin(RoomUsersFilterQuery.Members, props.user.id);
      }
    );
  };

  const confirmDeleteAdmin = async () => {
    await openDeleteUser(
      {
        roomId: roomStore.roomId,
        userId: props.user.id,
        userName: userName.value
      },
      () => {
        roomMemberStore.deleteUser(RoomUsersFilterQuery.Admins, props.user.id);
      }
    );
  };

  const openAdminModal = () => {
    openAdminPermissionsModal({
      roomId: roomStore.roomId,
      user: props.user,
      onSuccess: (permissions: TRoomAdminPermission[]) => {
        roomMemberStore.updateUser(props.updateType, {
          id: props.user.id,
          user_status: {
            status: RoomUserStatus.Admin,
            permissions
          }
        } as RoomUser);
      },
      onDelete: () => {
        closeAdminPermissionsModal();
        confirmDeleteAdmin();
      },
      onRemoveFromAdmin: () => {
        closeAdminPermissionsModal();
        confirmRemoveAdmin();
      }
    });
  };

  const menuItems = computed<NavLink[]>(() => {
    return [
      {
        itemKey: 'adminSettings',
        linkProps: {
          variant: userStatus.value.admin ? 'link' : 'link',
          iconLeft: userStatus.value.admin ? 'edit' : 'manage_accounts',
          iconExtend: true,
          text: userStatus.value.admin ? 'Edit Admin Settings' : 'Set as Admin',
          hideSpace: true,
          onClick: () => openAdminModal()
        }
      }
    ];
  });
</script>
