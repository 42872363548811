<template>
  <div class="grid grid-cols-1 gap-2.5 sm:gap-5">
    <div
      v-for="(item, i) in infoConfig"
      :key="i"
      class="grid grid-cols-1 gap-2.5"
    >
      <ui-typography
        variant="h3"
        tag="h3"
      >
        {{ item.name }}
      </ui-typography>

      <p>{{ item.value }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  type BaseItem = {
    name: string;
    value?: string | number;
  };
  type Props = {
    room: Room;
  };
  const props = defineProps<Props>();

  const infoConfig = computed<BaseItem[]>(() => {
    return [
      { name: 'About Author', value: props.room.about_author },
      { name: 'About This Room', value: props.room.about }
    ];
  });
</script>
