<template>
  <ui-container
    no-gutters-x
    spacing="none"
    class="flex flex-col flex-1 h-full"
  >
    <ui-loader
      :error="error"
      :loading="loading"
    />
    <ui-card
      v-if="isMd"
      class="grid grid-cols-12 mb-2.5"
    >
      <ui-back-link
        class="col-span-2"
        :to="backTo"
        is-small
      />
      <slot name="title">
        <ui-typography
          v-if="title"
          tag="h3"
          variant="h3"
          :text="title"
          class="text-center col-span-8"
        />
      </slot>
    </ui-card>
    <ui-card class="flex flex-1 h-full flex-col">
      <ui-card-contet-scroll>
        <ui-back-link
          v-if="!isMd"
          :to="backTo"
        />
        <div class="w-full mx-auto max-w-xl grid grid-cols-1 sm:gap-5 gap-2.5">
          <div class="grid grid-cols-1">
            <slot name="title">
              <ui-typography
                v-if="title && !isMd"
                tag="h3"
                variant="h3"
                :text="title"
                class="text-center mb-7"
              />
            </slot>
            <slot />
          </div>
        </div>
      </ui-card-contet-scroll>
    </ui-card>
  </ui-container>
</template>

<script setup lang="ts">
  type Props = { title?: string; loading?: boolean; error?: any; backTo?: string | object };
  defineProps<Props>();
  const isMd = useIsLessThan('md');
</script>
