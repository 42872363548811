<template>
  <common-room-admin-content-wrap
    :back-to="{ name: 'dashboard-rooms-roomId-admins', params: { roomId: $route.params.roomId } }"
    title="Add New Admin"
  >
    <common-room-user-list
      :type="RoomUsersFilterQuery.Members"
      :card-type="RoomUsersFilterQuery.Admins"
    />
  </common-room-admin-content-wrap>
</template>

<script setup lang="ts">
  import { RoomUsersFilterQuery } from '~/globals/rooms';

  useSeoMeta({
    title: 'New Admin'
  });
</script>
