import type { TRoomUsersFilterQuery } from '~/globals/rooms';

const initialUser = {
  meta: {},
  data: [],
  loading: false,
  isFirstLoad: true
} as ResWithCursorPagination<RoomUser[]> & {
  loading: boolean;
  isFirstLoad: boolean;
};
const makeUsersState = () => {
  return {
    [RoomUsersFilterQuery.Members]: { ...initialUser },
    [RoomUsersFilterQuery.Requests]: { ...initialUser },
    [RoomUsersFilterQuery.Admins]: { ...initialUser }
  };
};
const useRoomMemberStore = defineStore('room-member', () => {
  const apiRoutes = useApiRoutes();
  const roomStore = useRoomStore();
  const users = reactive(makeUsersState());
  const checkNextPageUsers = (type: TRoomUsersFilterQuery) => {
    return !!users[type].meta.next_cursor;
  };

  const fetchMembers = async (type: TRoomUsersFilterQuery, queryParams = {}, next?: boolean) => {
    users[type].loading = true;

    const params = {
      type,
      cursor: next ? users[type].meta.next_cursor : undefined,
      ...queryParams
    };
    const res = await apiRoutes.rooms.getMembers(roomStore.roomId, params);

    users[type].meta = res._data.meta;

    if (next) {
      users[type].data.push(...res._data.data);
    } else {
      users[type].data = res._data.data;
    }

    users[type].loading = false;
    users[type].isFirstLoad = false;

    return true;
  };

  const reset = () => {
    Object.assign(users, makeUsersState());
  };
  const approveUser = (approvedUser: RoomUser) => {
    users[RoomUsersFilterQuery.Requests].data = useFilter(
      users[RoomUsersFilterQuery.Requests].data,
      user => user.id !== approvedUser.id
    );
    const userStatus = {
      ...(approvedUser.user_status || {}),
      status: RoomUserStatus.Member
    } as RoomUserStatusType;
    users[RoomUsersFilterQuery.Members].data.unshift({
      ...approvedUser,
      user_status: userStatus
    });
    if (roomStore.room) {
      roomStore.updateRoom({
        members_count: roomStore.room.members_count + 1
      });
    }
  };

  const deleteUser = (type: TRoomUsersFilterQuery, userId: ResourceId) => {
    users[type].data = users[type].data.filter(user => {
      return user.id !== userId;
    });

    if (roomStore.room) {
      roomStore.updateRoom({
        members_count: Math.max(roomStore.room.members_count - 1, 0)
      });
    }
  };

  const updateUser = (type: TRoomUsersFilterQuery, user: Partial<RoomUser>) => {
    users[type].data = updateItemArray<RoomUser, 'id'>(users[type].data, user);
  };

  const deleteUserFromAdmin = (type: TRoomUsersFilterQuery, userId: ResourceId) => {
    updateUser(type, {
      id: Number(userId),
      user_status: {
        status: RoomUserStatus.Member,
        permissions: null
      }
    } as RoomUser);
  };

  return {
    users,
    reset,
    approveUser,
    fetchMembers,
    deleteUserFromAdmin,
    deleteUser,
    checkNextPageUsers,
    updateUser
  };
});

export default useRoomMemberStore;
