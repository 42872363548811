<template>
  <div class="grid grid-cols-1 divide-y divide-card-placeholder-border">
    <ui-expansion-panels
      v-for="item in roomUsersList"
      :key="item.type"
      :model-value="0"
      class="py-5 last:pb-0 first:pt-0 flex flex-col gap-2.5"
    >
      <ui-expansion-panel :title="item.title">
        <template #body>
          <common-room-user-list
            :type="item.type"
            class="pt-2.5"
            wrap-classes="max-h-[250px]"
          />
        </template>
      </ui-expansion-panel>
    </ui-expansion-panels>
  </div>
</template>

<script setup lang="ts">
  import { RoomUsersFilterQuery } from '~/globals/rooms';

  const roomStore = useRoomStore();
  const userPermission = computed(() => roomStore.userPermission);
  const roomUsersList = computed(() => {
    const items = [{ title: 'Members', type: RoomUsersFilterQuery.Members }];
    if (userPermission.value.RejectRequests || userPermission.value.ApproveRequests) {
      items.unshift({
        title: 'Requests',
        type: RoomUsersFilterQuery.Requests
      });
    }
    return items;
  });
</script>
