<template>
  <div
    ref="userCard"
    class="overflow-hidden swiper relative"
  >
    <common-room-user-base-card
      :user="user"
      class="overflow-hidden default-transition"
      :class="{ '-translate-x-32': isSwipedMenu }"
    >
      <common-room-user-status
        v-if="!memberUserStatus.member"
        :status="status"
      />
      <ui-dropdown-menu
        v-if="!isMobile && isShowMenu"
        placement="top-end"
        :skidding="22"
        :distance="-62"
        :menu-items="menuItems"
        btn-class="absolute top-2 right-5"
      />
    </common-room-user-base-card>
    <div
      v-if="isMobile"
      class="swiper__actions absolute right-0 translate-x-32 z-1 top-1/2 -translate-y-1/2 default-transition"
      :class="{ '!translate-x-0 !right-5': isSwipedMenu }"
    >
      <div class="flex sm:gap-0.5">
        <ui-button
          v-for="item in menuItems"
          :key="item.itemKey"
          rounded="none"
          v-bind="item.linkProps"
          class="w-12 p-1.5 text-[8px] h-10 leading-none"
          :class="menuItems.length === 1 ? 'rounded-md' : 'last:rounded-r first:rounded-l-lg'"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { TRoomAdminPermission, TRoomUsersFilterQuery } from '~/globals/rooms';

  type Props = {
    user: RoomUser;
    updateType: TRoomUsersFilterQuery;
    isSwipedMenu?: boolean;
  };
  const props = defineProps<Props>();
  const emits = defineEmits(['toggleSwipe']);
  const roomStore = useRoomStore();
  const userCard = ref();
  const roomMemberStore = useRoomMemberStore();
  const userPermission = computed(() => roomStore.userPermission);
  const userStatus = computed(() => roomStore.userStatus);
  const isMobile = useIsLessThan('sm');
  const userName = useUserFullName(props, 'user');
  const status = computed(() => props.user.user_status?.status);
  const memberUserStatus = computed(() => getUserStatusForRoom(props.user));
  const isShowMenu = computed(() => !memberUserStatus.value.owner && menuItems.value.length);

  const onToggleSwipe = (userId: number = 0) => {
    emits('toggleSwipe', userId);
  };

  useSwipe(userCard, {
    onSwipeEnd(_, direct) {
      if (isMobile.value && isShowMenu.value && ['right', 'left'].includes(direct)) {
        onToggleSwipe(direct === 'left' ? props.user.id : 0);
      }
    }
  });

  const modalData = computed(() => {
    return {
      roomId: roomStore.roomId,
      userId: props.user.id,
      userName: userName.value
    };
  });
  const { action: openRemoveAdminModal } = useConfirmRemoveAdminModal();
  const { action: openDeleteUser } = useConfirmDeleteUserFormRoom();
  const confirmRemoveAdmin = async () => {
    await openRemoveAdminModal(modalData.value, () => {
      roomMemberStore.deleteUserFromAdmin(RoomUsersFilterQuery.Members, props.user.id);
    });
  };
  const confirmDeleteAdmin = async () => {
    await openDeleteUser(modalData.value, () => {
      roomMemberStore.deleteUser(RoomUsersFilterQuery.Members, props.user.id);
    });
  };
  const { open: openAdminPermissionsModal } = useAdminPermissionsModal({});
  const openAdminModal = () => {
    openAdminPermissionsModal({
      roomId: roomStore.roomId,
      user: props.user,
      isCreate: true,
      onSuccess: (permissions: TRoomAdminPermission[]) => {
        roomMemberStore.updateUser(props.updateType, {
          id: props.user.id,
          user_status: {
            status: RoomUserStatus.Admin,
            permissions
          }
        });
      }
    });
  };
  const menuItems = computed<NavLink[]>(() => {
    const menu: NavLink[] = [];
    if (userStatus.value.owner && memberUserStatus.value.admin) {
      menu.push({
        itemKey: 'removeFromAdmin',
        linkProps: {
          variant: isMobile.value ? 'secondary' : 'link',
          iconLeft: isMobile.value ? undefined : 'block',
          iconExtend: !isMobile.value,
          text: 'Remove from Admin',
          hideSpace: true,
          onClick: () => {
            onToggleSwipe();
            confirmRemoveAdmin();
          }
        }
      });
    }

    if (userStatus.value.owner && memberUserStatus.value.member) {
      menu.push({
        itemKey: 'setAsAdmin',
        linkProps: {
          variant: isMobile.value ? 'secondary' : 'link',
          iconLeft: isMobile.value ? undefined : 'manage_accounts',
          iconExtend: !isMobile.value,
          text: 'Set as Admin',
          hideSpace: true,
          onClick: () => {
            onToggleSwipe();
            openAdminModal();
          }
        }
      });
    }

    if (userPermission.value.DeleteMembers && (!userStatus.value.admin || memberUserStatus.value.member)) {
      menu.push({
        itemKey: 'DeleteFromRoom',
        linkProps: {
          variant: isMobile.value ? 'error' : 'error-link',
          iconLeft: 'delete',
          iconExtend: !isMobile.value,
          text: isMobile.value ? undefined : 'Delete from Room',
          iconClassLeft: 'text-lg',
          hideSpace: true,
          onClick: () => {
            onToggleSwipe();
            confirmDeleteAdmin();
          }
        }
      });
    }

    return menu;
  });
</script>
