<template>
  <common-room-admin-content-wrap
    :back-to="{ name: 'dashboard-rooms-roomId', params: { roomId: $route.params.roomId } }"
    title="Administrators"
  >
    <div class="flex flex-col gap-5 sm:divide-y-0 divide-y divide-card-placeholder-border">
      <div>
        <ui-button
          variant="link"
          icon-extend
          hide-space
          class="text-xl"
          icon-left="add"
          text="Add New Admin"
          :to="{
            name: 'dashboard-rooms-roomId-admins-create'
          }"
        />
      </div>
      <common-room-user-list
        hide-search
        :type="RoomUsersFilterQuery.Admins"
      />
    </div>
  </common-room-admin-content-wrap>
</template>

<script setup lang="ts">
  import { RoomUsersFilterQuery } from '~/globals/rooms';

  useSeoMeta({
    title: 'Administrators'
  });
</script>
