<template>
  <common-room-user-base-card
    ref="userCard"
    :user="user"
  >
    <div
      v-if="actions.length"
      class="flex gap-2.5"
    >
      <ui-button-async
        v-for="(action, index) in actions"
        :key="index"
        :icon-left="action?.iconLeft"
        :variant="action?.variant"
        :request="action.request"
        size="sm-icon"
        rounded="sm"
        @success="action?.onSuccess"
      />
    </div>
  </common-room-user-base-card>
</template>

<script setup lang="ts">
  import { RoomUsersFilterQuery } from '~/globals/rooms';

  type Props = {
    user: RoomUser;
  };
  type ActionConfigType = AsyncButtonProps & { onSuccess?: () => any };
  const props = defineProps<Props>();
  const roomMemberStore = useRoomMemberStore();
  const roomStore = useRoomStore();
  const userPermission = computed(() => roomStore.userPermission);

  const actions = computed<ActionConfigType[]>(() => {
    const menu = [] as ActionConfigType[];
    if (userPermission.value.ApproveRequests) {
      menu.push({
        iconLeft: 'check',
        request: () => roomStore.onApproveUser(props.user.id),
        onSuccess: () => {
          roomMemberStore.approveUser(props.user);
        }
      });
    }
    if (userPermission.value.RejectRequests) {
      menu.push({
        iconLeft: 'block',
        request: () => roomStore.onRejectUser(props.user.id),
        variant: 'secondary',
        onSuccess: () => {
          roomMemberStore.deleteUser(RoomUsersFilterQuery.Requests, props.user.id);
        }
      });
    }
    return menu;
  });
</script>
