<template>
  <ui-dropdown-menu
    :menu-items="menuItems"
    placement="bottom-end"
  >
    <template #btn>
      <ui-button
        variant="secondary"
        size="sm-icon"
        icon-left="edit"
      />
    </template>
  </ui-dropdown-menu>
</template>

<script setup lang="ts">
  type Props = { roomId: ResourceId };
  const props = defineProps<Props>();
  const roomStore = useRoomStore();
  const defaultLinkProps: NavLink['linkProps'] = {
    variant: 'link',
    iconExtend: true,
    size: 'md',
    hideSpace: true
  };

  const menuItems = computed<NavLink[]>(() => {
    const menu = [
      {
        itemKey: 'generalSettings',
        linkProps: {
          iconLeft: 'settings',
          text: 'General Settings',
          to: {
            name: 'dashboard-rooms-roomId-edit',
            params: { roomId: props.roomId }
          },
          ...defaultLinkProps
        }
      }
    ];
    if (roomStore.userStatus.owner) {
      menu.push({
        itemKey: 'administrators',
        linkProps: {
          text: 'Administrators',
          iconLeft: 'manage_accounts',
          to: {
            name: 'dashboard-rooms-roomId-admins',
            params: { roomId: props.roomId }
          },
          ...defaultLinkProps
        }
      });
    }

    return menu;
  });
</script>
