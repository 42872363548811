const useConfirmDeleteUserFormRoom = () => {
  const { handler, loading } = useHandlerErrorAndLoadingClient();
  const roomStore = useRoomStore();
  const { open: openConfirmModal } = useConfirmModal({});
  const action = (
    data: {
      roomId: ResourceId;
      userId: ResourceId;
      userName: string;
    },
    onSuccessFn: () => void
  ): Promise<boolean> =>
    new Promise(resolve => {
      const { roomId, userId, userName } = data;
      return openConfirmModal({
        title: `Do you really want to delete ${userName} from room?`,
        confirmText: 'Yes, Delete',
        confirmAction: async () => {
          const res = await handler(roomStore.onRejectUser(Number(userId), Number(roomId)));
          return !res.isError;
        },
        onSuccess: () => {
          onSuccessFn();
          resolve(true);
        },
        onReject: () => resolve(false)
      });
    });

  return { action, loading };
};

const useConfirmRemoveAdminModal = () => {
  const { handler, loading } = useHandlerErrorAndLoadingClient();
  const apiRoutes = useApiRoutes();
  const { open: openConfirmModal } = useConfirmModal({});
  const action = (
    data: {
      roomId: string | number;
      userId: string | number;
      userName: string;
    },
    onSuccessFn: () => void
  ): Promise<boolean> =>
    new Promise(resolve => {
      const { roomId, userId, userName } = data;
      return openConfirmModal({
        title: `Are you really want to delete ${userName} from admin?`,
        confirmText: 'Yes, Remove',
        confirmAction: async () => {
          const res = await handler(apiRoutes.rooms.deleteUserFromAdmin(roomId, userId));
          return !res.isError;
        },
        onSuccess: () => {
          onSuccessFn();
          resolve(true);
        },
        onReject: () => resolve(false)
      });
    });

  return { action, loading };
};

export { useConfirmRemoveAdminModal, useConfirmDeleteUserFormRoom };
