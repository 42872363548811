<template>
  <div
    v-if="isSupported"
    class="flex gap-2.5 items-center"
  >
    <ui-button
      variant="link"
      icon-left="add_link"
      icon-extend
      hide-space
      :text="copied ? 'Invitation Link is copied' : 'Invitation Link'"
      @click="copy(source)"
    />
  </div>
</template>

<script setup lang="ts">
  const currentRequestUrl = useRequestURL();
  const source = computed(() => currentRequestUrl?.href);
  const { copy, copied, isSupported } = useClipboard({ source });
</script>
